<template>
    <comments-module-component/>
</template>

<script>
    import CommentsModuleComponent from "@/components/admin/CommentsModuleComponent";
    
    export default {
        name: "CommentsModule",
        title: "Gestión de Comentarios | Turismo BC",
        components: { CommentsModuleComponent }
    }
</script>

<style scoped>

</style>